<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-toolbar-title class="body-2 white--text">
        {{ this.$route.meta.subtitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn small fab dark class="mx-1" color="primary" @click="reload">
        <v-icon dark>autorenew</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        fill-height
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row no-gutters align-space-around justify-center>
          <v-col cols="12">
            <InteractiveTable
              ref="registrationList"
              class="lg-table"
              :headers="[
                { text: ' ', value: 'added' },
                { text: 'ID', value: 'id' },
                { text: 'Vrsta', value: 'regtype' },
                { text: 'Vrijeme prijave', value: 'registrationdate' },
                { text: 'Ime i prezime', value: 'name' },
                { text: 'Fakultet/Tvrtka', value: 'faculty' },
                { text: 'OIB', value: 'oib' },
                { text: 'Adresa', value: 'address' },
                { text: 'AAI', value: 'aai' },
                { text: 'E-mail', value: 'email' }
              ]"
              apiLoad="registration/registrations.php"
              :actions="[
                {
                  id: '1',
                  name: 'Dodaj u bazu',
                  eventCall: 'addUser',
                  icon: 'person_add',
                  color: 'secondary'
                },
                {
                  id: '2',
                  name: 'Označi/odznači kao riješeno',
                  eventCall: 'changeAdded',
                  icon: 'check_circle_outline',
                  color: 'orange'
                }
              ]"
              defaultFilter="name"
              defaultSort="registrationdate"
              defaultSortDesc
              @addUser="addUser"
              @changeAdded="changeAdded"
            ></InteractiveTable>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-dialog v-model="oibExists" width="500">
        <v-card>
          <v-card-title>Upozorenje</v-card-title>
          <v-card-text
            >OIB za ovu osobu već postoji u bazi! Provjerite u bazi postojeću
            osobu i preuredite s novim podacima iz prijave ako je
            potrebno.</v-card-text
          >
          <v-card-actions>
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="secondary"
              :to="'/useredit/' + foundId"
              >UREDI POSTOJEĆU OSOBU</v-btn
            >
            <v-btn
              small
              dark
              rounded
              class="mx-1"
              color="grey"
              @click="oibExists = false"
              >ZATVORI PORUKU</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>

<script>
import InteractiveTable from "@/components/InteractiveTable.vue";

export default {
  name: "RegistrationList",
  data() {
    return {
      overlay: false,
      oibExists: false,
      foundId: 0
    };
  },
  components: {
    InteractiveTable
  },
  computed: {
    needsRefresh: function() {
      return this.$store.getters.registrationListNeedsRefresh;
    }
  },
  watch: {
    needsRefresh(needsRefresh) {
      if (needsRefresh) {
        this.reload();
      }
    }
  },
  methods: {
    reload() {
      this.$refs.registrationList.reload();
      this.$store.dispatch("registration_list_refreshed");
    },
    changeAdded(item) {
      // update added
      this.$http
        .put("/registration/registration.php", {
          params: {
            id: item.id,
            added: item.added == 1 ? 0 : 1
          }
        })
        .then(() => {
          this.reload();
        })
        .catch(error => {
          console.log(error);
        });
    },
    addUser(item) {
      var fname = "";
      var lname = "";
      var indspace = item.name.indexOf(" ");
      if (indspace >= 0) {
        fname = item.name.substr(0, indspace);
        lname = item.name.substr(indspace + 1);
      } else {
        fname = item.name;
      }
      var formdata = {
        user_name: (fname.substring(0, 1) + lname).toLowerCase(),
        first_name: fname,
        last_name: lname,
        email: item.email && item.email.length > 0 ? item.email : item.aai,
        title: item.title,
        address: item.address,
        city: null,
        postal_code: null,
        country: null,
        phone: item.phone,
        mobile: item.mobile,
        fax: null,
        birthday: item.date,
        oib: item.oib,
        membership_pays:
          item.regtype == "student" || item.membertype == "Počasni članovi"
            ? 0
            : 1,
        membership_name: "Individualna privatna",
        membership_discount:
          item.membertype == "Redovni članovi - mladi inženjeri" ||
          item.membertype == "Redovni članovi - umirovljenici"
            ? 50
            : null,
        membership_private: item.payc == 1 ? 0 : 1,
        suffix: null,
        sex: null,
        vocation: null,
        birthplace: item.birthplace,
        personal_status: null,
        materials_to_private_address: null,
        accession: 1,
        note: null,
        email1: null,
        email2: null,
        email3: null,
        phone1: null,
        phone2: null,
        phone3: null,
        mobile1: null,
        fax1: null,
        i_membership_pays: 0,
        i_membership_name: null,
        i_membership_discount: null,
        i_membership_regnum: null,
        i_membership_private: null,
        mbz: item.mbz
      };
      this.overlay = true;
      // check if oib exists in db
      this.$http
        .get("user/users.php", {
          params: {
            page: 0,
            page_size: 1,
            filter_column: "oib",
            filter: item.oib,
            sort_column: ["id"],
            sort_direction: "DESC"
          }
        })
        .then(response => {
          if (response.data.rows.length > 0) {
            this.oibExists = true;
            this.foundId = response.data.rows[0].id;
            this.overlay = false;
          } else {
            this.$http
              .post("user/user.php", {
                params: {
                  form: formdata
                }
              })
              .then(response => {
                if (response.data.success) {
                  // get last inserted user
                  this.$http
                    .get("user/users.php", {
                      params: {
                        page: 0,
                        page_size: 1,
                        sort_column: ["id"],
                        sort_direction: "DESC"
                      }
                    })
                    .then(response => {
                      // update added
                      this.$http
                        .put("/registration/registration.php", {
                          params: {
                            id: item.id,
                            added: true
                          }
                        })
                        .then(() => {
                          // navigate to user edit
                          this.$router.push(
                            "/useredit/" + response.data.rows[0].id
                          );
                          this.overlay = false;
                          this.reload();
                        })
                        .catch(error => {
                          console.log(error);
                          this.overlay = false;
                        });
                    })
                    .catch(error => {
                      console.log(error);
                      this.overlay = false;
                    });
                } else {
                  this.overlay = false;
                }
              })
              .catch(error => {
                console.log(error);
                this.overlay = false;
              });
          }
        })
        .catch(error => {
          console.log(error);
          this.overlay = false;
        });
    }
  }
};
</script>
