var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(" "+_vm._s(this.$route.meta.subtitle)+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","fab":"","dark":"","color":"primary"},on:{"click":_vm.reload}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("autorenew")])],1)],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":"","fill-height":""}},[_c('v-row',{attrs:{"no-gutters":"","align-space-around":"","justify-center":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('InteractiveTable',{ref:"registrationList",staticClass:"lg-table",attrs:{"headers":[
              { text: ' ', value: 'added' },
              { text: 'ID', value: 'id' },
              { text: 'Vrsta', value: 'regtype' },
              { text: 'Vrijeme prijave', value: 'registrationdate' },
              { text: 'Ime i prezime', value: 'name' },
              { text: 'Fakultet/Tvrtka', value: 'faculty' },
              { text: 'OIB', value: 'oib' },
              { text: 'Adresa', value: 'address' },
              { text: 'AAI', value: 'aai' },
              { text: 'E-mail', value: 'email' }
            ],"apiLoad":"registration/registrations.php","actions":[
              {
                id: '1',
                name: 'Dodaj u bazu',
                eventCall: 'addUser',
                icon: 'person_add',
                color: 'secondary'
              },
              {
                id: '2',
                name: 'Označi/odznači kao riješeno',
                eventCall: 'changeAdded',
                icon: 'check_circle_outline',
                color: 'orange'
              }
            ],"defaultFilter":"name","defaultSort":"registrationdate","defaultSortDesc":""},on:{"addUser":_vm.addUser,"changeAdded":_vm.changeAdded}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.oibExists),callback:function ($$v) {_vm.oibExists=$$v},expression:"oibExists"}},[_c('v-card',[_c('v-card-title',[_vm._v("Upozorenje")]),_c('v-card-text',[_vm._v("OIB za ovu osobu već postoji u bazi! Provjerite u bazi postojeću osobu i preuredite s novim podacima iz prijave ako je potrebno.")]),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","dark":"","rounded":"","color":"secondary","to":'/useredit/' + _vm.foundId}},[_vm._v("UREDI POSTOJEĆU OSOBU")]),_c('v-btn',{staticClass:"mx-1",attrs:{"small":"","dark":"","rounded":"","color":"grey"},on:{"click":function($event){_vm.oibExists = false}}},[_vm._v("ZATVORI PORUKU")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }